import { getHeaderWithToken } from "./utils";

export const get = (url, options = {}, withAuth = true) => {
  const headers = getHeader(withAuth);

  return fetch(url, { ...options, ...headers, method: "GET" });
};

export const post = (url, data, options = {}, withAuth = true) => {
  const headers = getHeader(withAuth);
  return fetch(url, {
    ...options,
    ...headers,
    method: "POST",
    body: JSON.stringify(data),
  });
};

const getHeader = (withAuth) => {
  let headers = {};
  if (withAuth) headers = getHeaderWithToken();
  return headers;
};
