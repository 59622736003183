import React from "react";
import {
  Select,
  withStyles,
  makeStyles,
  InputLabel,
  FormControl,
} from "@material-ui/core";

const CssSelectField = withStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    color: theme.palette.secondary.dark,
    padding: "0.665rem 2.1875rem 0.665rem 1rem",
    paddingRight: "2.1875rem",
    fontSize: theme.typography.body1.fontSize,
    borderRadius: theme.shape.borderRadius,
    minWidth: "4rem",
    border: "0.01rem solid #526161",
    "&:hover": {
      boxShadow: "0 0 0.4rem #ccc",
      borderColor: "#526161",
    },
    "&:focus": {
      boxShadow: theme.shadows[2],
      background: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      boxShadow: "0 0 0.4rem #ccc",
      borderColor: "#526161",
    },
  },
}))(Select);

const useStyles = makeStyles((theme) => ({
  fromControl: {
    "& fieldset": {
      borderColor: "#526161 !important",
    },
    "&:hover fieldset": {
      borderColor: "#526161",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#526161",
    },
    "& label.MuiInputLabel-outlined": {
      zIndex: 1,
      transform: "translate(14px, 14px) scale(1)",
      PointerEvent: "none",
    },
    "& label.Mui-focused": {
      color: "#526161 !important",
      transform: "translate(14px, -4px) scale(0.75)",
      background: "white",
      padding: "0 6px",
    },
    "& label.MuiFormLabel-filled": {
      transform: "translate(14px, -4px) scale(0.75)",
      background: "white",
      padding: "0 6px",
    },
  },
}));

const SelectInput = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <FormControl variant="outlined" className={classes.fromControl}>
        <InputLabel id={props.labelId}>{props.label}</InputLabel>
        <CssSelectField {...props}>{props.children}</CssSelectField>
      </FormControl>
    </React.Fragment>
  );
};

export default SelectInput;
