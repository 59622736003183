import { get } from "./api";
import {
  FACILITY_URL,
  INSPECTIONFORM_URL,
  ISNPECTIONS_URL,
} from "./api/endpoints";

export const getInspectionForms = async () => {
  return get(`${INSPECTIONFORM_URL}?company=${localStorage.getItem("group")}`)
    .then(handleResponse)
    .then((data) => {
      const dataWithId = data.map((row, index) => {
        row.id = index + 1;
        return row;
      });
      localStorage.setItem("inspectionForms", JSON.stringify(dataWithId));
      return dataWithId;
    });
};

export const getFacility = async () => {
  return get(`${FACILITY_URL}?company=${localStorage.getItem("group")}`)
    .then(handleResponse)
    .then((data) => {
      const dataWithId = data.map((row, index) => {
        row.id = index + 1;
        return row;
      });
      localStorage.setItem("facilitiesData", JSON.stringify(dataWithId));
      return dataWithId;
    });
};

export const getInspections = async () => {
  return get(`${ISNPECTIONS_URL}?company=${localStorage.getItem("group")}`)
    .then(handleResponse)
    .then((data) => {
      const dataWithId = data.map((row, index) => {
        row.id = index + 1;
        return row;
      });
      localStorage.setItem("inspections", JSON.stringify(dataWithId));
      return dataWithId;
    });
};

export const handleResponse = async (resp) => {
  const data = await resp.json();
  return data;
};
