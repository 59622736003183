import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Box, Grid, Button, FormControl } from "@material-ui/core";
import MenuComponent from "../MenuComponent";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../UI/Table";
import TextInput from "../UI/TextInput";
import { TABLE_HEADERS_MY_FACILITIES } from "../../common/constants";
import { getFacility } from "../../common/dataGetters";
import { saveFacility } from "../../common/dataUpdators";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const emptyFormData = {
  entity_name: "",
  facilityName: "",
  pointOfContact: "",
  facilityAddress: "",
  country: "",

  facilityCompany: "",
  facilityParentCompany: "",
  facilityType: "",
  countryCode: "",
  facilityContactPerson: "",
  facilityId: "",
  createdDate: "",
};

const LandingPageComponent = (props) => {
  const user = localStorage.getItem("group");
  const classes = useStyles();
  const [formData, setFormData] = useState({ ...emptyFormData });
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [editRowEnabled, setEditRowEnabled] = useState(false);
  const token = localStorage.getItem("token");
  const history = useHistory();
  if (!token) {
    history.push("/login");
  }
  const [editOpen, setEditOpen] = React.useState(false);

  const [openForm, setOpenForm] = React.useState(false);

  useEffect(() => {
    setEditRowEnabled(selectionModel.length === 1);
  }, [selectionModel]);

  const handleClickOpenForm = () => {
    setOpenForm(true);
  };

  const handleClickEditForm = () => {
    const data = facilitiesData[selectionModel[0] - 1];
    setFormData(data);
    setEditOpen(true);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setEditOpen(false);
    setFormData({ ...emptyFormData });
  };

  const handleInputChange = (field, data) => {
    const formDataClone = JSON.parse(JSON.stringify(formData));
    formDataClone[field] = data;
    setFormData(formDataClone);
  };

  const hasValue = (data) => {
    return Boolean(data) && Boolean(String(data).trim());
  };

  const isFormValid = () => {
    return (
      hasValue(formData.facilityName) &&
      hasValue(formData.facilityAddress) &&
      hasValue(formData.country) &&
      hasValue(formData.pointOfContact)
    );
  };

  const handleSave = () => {
    if (!isFormValid()) {
      alert("Please fill all required fields");
      return;
    }
    const facilitiesDataClone = JSON.parse(JSON.stringify(facilitiesData));
    const formDataClone = JSON.parse(JSON.stringify(formData));
    formDataClone.entity_name = user;
    formDataClone.facilityId = editOpen
      ? formDataClone.facility_id
      : Number(new Date()).toString();
    formDataClone.id = formDataClone.facilityId;
    formDataClone.createdDate = moment().format("MM/DD/YYYY");
    facilitiesDataClone.push(formDataClone);
    saveFacility(formDataClone).then((data) => {
      fetchFacility();
      setFormData({ ...emptyFormData });
    });

    handleCloseForm();
  };

  React.useEffect(() => {
    fetchFacility();
  }, []);

  const fetchFacility = () => {
    getFacility().then((data) => {
      setFacilitiesData(data);
    });
  };

  return (
    <React.Fragment>
      <MenuComponent>
        <Dialog
          open={openForm}
          onClose={handleCloseForm}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Add Facility</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  {/* <InputLabel id="demo-simple-select-label">
                    Facility Name
                  </InputLabel> */}
                  <TextInput
                    value={formData.facilityName}
                    required
                    variant="outlined"
                    label="Facility Name"
                    onChange={(event) =>
                      handleInputChange("facilityName", event.target.value)
                    }
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <TextInput
                    value={formData.facilityCompany}
                    variant="outlined"
                    label="Facility Company"
                    onChange={(event) =>
                      handleInputChange("facilityCompany", event.target.value)
                    }
                  />
                </FormControl>
              </Grid> */}
              {/* <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <TextInput
                    value={formData.facilityParentCompany}
                    variant="outlined"
                    label="Facility Parent-Company"
                    onChange={(event) =>
                      handleInputChange(
                        "facilityParentCompany",
                        event.target.value
                      )
                    }
                  />
                </FormControl>
              </Grid> */}
              {/* <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <SelectInput
                    value={formData.facilityType}
                    labelId="facility-type-label"
                    id="demo-simple-select"
                    input={<OutlinedInput margin="dense" />}
                    variant="outlined"
                    fullWidth
                    label="Facility Type"
                    inputProps={{ size: "small" }}
                    onChange={(event) =>
                      handleInputChange("facilityType", event.target.value)
                    }
                  >
                    <MenuItem value="Farm">Farm/Ranch/Plantation</MenuItem>
                    <MenuItem value="Mill">Gin/Mill/Processing-Plant</MenuItem>
                    <MenuItem value="Refinery">Refinery/Cannery/Winery</MenuItem>
                    <MenuItem value="Factory">Factory</MenuItem>
                  </SelectInput>
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <TextInput
                    value={formData.facilityAddress}
                    multiline
                    required
                    variant="outlined"
                    label="Facility Address"
                    onChange={(event) =>
                      handleInputChange("facilityAddress", event.target.value)
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  {/* <InputLabel id="demo-simple-select-label">
                    Country Code
                  </InputLabel> */}
                  <TextInput
                    value={formData.country}
                    variant="outlined"
                    required
                    label="Country"
                    onChange={(event) =>
                      handleInputChange("country", event.target.value)
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  {/* <InputLabel id="demo-simple-select-label">
                    Facility SME Team
                  </InputLabel> */}
                  <TextInput
                    value={formData.pointOfContact}
                    variant="outlined"
                    required
                    label="Point of Contact"
                    onChange={(event) =>
                      handleInputChange("pointOfContact", event.target.value)
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}></Grid>
              {/* <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    <CalendarTodayIcon /> Created Date
                  </InputLabel>
                </FormControl>

                <DatePicker
                  labelId="demo-simple-select-label"
                  className="MuiInputBase-input"
                  // selected={selectedDate}
                  // onChange={handleDateChange}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <Button
                    variant="contained"
                    color="primary"
                    // onClick={() => handleClickOpenForm()}
                  >
                    Save
                  </Button>
                </FormControl>
              </Grid> */}
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleCloseForm} color="primary">
              Cancel
            </Button> */}
            <Button onClick={() => handleSave()} className="full-color-button">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container>
          <Grid item md={12}></Grid>
          <Grid item md={12} className="flex-right-justified">
            {editRowEnabled && (
              <Box p={2}>
                <Button
                  variant="contained"
                  className="full-color-button"
                  onClick={() => handleClickEditForm()}
                >
                  Edit Facility
                </Button>
              </Box>
            )}
            <Box p={2}>
              <Button
                variant="contained"
                className="full-color-button"
                onClick={() => handleClickOpenForm()}
              >
                Add Facility
              </Button>
            </Box>
          </Grid>
          <Grid item md={12} className="table-container-height">
            <div className="ag-theme-alpine grid-main">
              <Table
                columns={TABLE_HEADERS_MY_FACILITIES}
                rows={facilitiesData}
                handleRowClick={({ selectionModel }) => {
                  setSelectionModel(selectionModel);
                }}
              />

              {/* <AgGridReact
                rowData={facilitiesData}
                rowSelection="single"
                onRowClicked={(event) => {
                  setFormData(event.api.getSelectedRows()[0]);
                  handleClickOpenForm();
                }}
              >
                <AgGridColumn
                  field="facility_id"
                  sortable={true}
                  filter={true}
                  resizable
                ></AgGridColumn>
                <AgGridColumn
                  field="facilityName"
                  sortable={true}
                  filter={true}
                  resizable
                ></AgGridColumn>
                <AgGridColumn
                  field="facilityCompany"
                  sortable={true}
                  filter={true}
                  resizable
                ></AgGridColumn>
                <AgGridColumn
                  field="facilityParentCompany"
                  sortable={true}
                  filter={true}
                  resizable
                ></AgGridColumn>
                <AgGridColumn
                  field="facilityType"
                  sortable={true}
                  filter={true}
                  resizable
                ></AgGridColumn>
                <AgGridColumn
                  field="facilityAddress"
                  sortable={true}
                  filter={true}
                  resizable
                ></AgGridColumn>
                <AgGridColumn
                  field="countryCode"
                  sortable={true}
                  filter={true}
                  resizable
                ></AgGridColumn>
                <AgGridColumn
                  field="facilityContactPerson"
                  sortable={true}
                  filter={true}
                  resizable
                ></AgGridColumn>
                <AgGridColumn
                  field="createdDate"
                  sortable={true}
                  filter={true}
                  resizable
                ></AgGridColumn>
              </AgGridReact> */}
            </div>
          </Grid>
        </Grid>
      </MenuComponent>
    </React.Fragment>
  );
};
export default LandingPageComponent;
