import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Divider,
  Paper,
  Input,
} from "@material-ui/core";
import TextInput from "../UI/TextInput";
import SelectInput from "../UI/SelectInput";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getInspectionForms, getInspections } from "../../common/dataGetters";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuComponent from "../MenuComponent";
import SectionComponent from "./section";
import FormComponent from "./from";
import Table from "../UI/Table";
import Progress from "../UI/Progress/CircularProgress";
import { TABLE_HEADERS_INSPECTION } from "../../common/constants";
import { saveInspection } from "../../common/dataUpdators";

const date = new Date();

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  formControlInput: {
    margin: theme.spacing(0),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
}));

const InspectionComponent = (props) => {
  const [inspectionData, setInspectionData] = useState([]);
  const user = localStorage.getItem("user");
  const classes = useStyles();
  const [reviewType, setReviewType] = React.useState("");
  const [formType, setFormType] = React.useState(-1);
  const [facility, setFacility] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [audit, setAudit] = React.useState("");
  const [reviewLevel, setReviewLevel] = React.useState(0);
  const [startDate, setStartDate] = React.useState(0);
  const [endDate, setEndDate] = React.useState(0);
  const [auditTeam, setAuditTeam] = React.useState("");
  const [auditCompany, setAuditCompany] = React.useState("");
  const [auditor, setAuditor] = React.useState("");
  const [sections, setSections] = React.useState([]);
  const [score, setScore] = React.useState("");
  const [result, setResult] = React.useState("");
  const [fdaApproved, setFdaApproved] = React.useState("");
  const [fdaCertified, setFdaCertified] = React.useState("");
  const [coverageDays, setCoverageDays] = React.useState(0);
  const [notificationDays, setNotificationDays] = React.useState(0);
  const [sectionData, setSectionData] = React.useState({});
  const [selectionModel, setSelectionModel] = useState([]);
  const [viewRowEnabled, setViewRowEnabled] = useState([]);
  const [viewOnly, setViewOnly] = useState(false);
  const [tableRefreshDate, setTableRefreshDate] = useState(new Date());
  const [inspections, setInspections] = React.useState([]);
  const inspectionForms = JSON.parse(localStorage.getItem("inspectionForms"));
  const facilitiesData = JSON.parse(localStorage.getItem("facilitiesData"));
  const [formEditing, setFormEditing] = useState(false);
  const [auditId, setAuditId] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const token = localStorage.getItem("token");
  const history = useHistory();
  if (!token) {
    history.push("/login");
  }
  useEffect(() => {
    setViewRowEnabled(selectionModel.length === 1);
  }, [selectionModel]);

  const handleChangeCoverageDays = (event) => {
    setCoverageDays(event.target.value);
  };
  const handleNotificationDaysChange = (event) => {
    setNotificationDays(event.target.value);
  };
  const handleScoreChange = (event) => {
    setScore(event.target.value);
  };
  const handleResultChange = (event) => {
    setResult(event.target.value);
  };
  const handleChangeReviewType = (event) => {
    setReviewType(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleChangeAuditor = (event) => {
    setAuditor(event.target.value);
  };

  const handleChangeAuditCompany = (event) => {
    setAuditCompany(event.target.value);
  };
  const handleChangeAuditTeam = (event) => {
    setAuditTeam(event.target.value);
  };

  const handleFDAChange = (type, event) => {
    if (type === "Approved") {
      setFdaApproved(event);
    }
    if (type === "Certified") {
      setFdaCertified(event);
    }
  };

  const handleChangeFormType = (event) => {
    setFormType(event.target.value);
    const formSectionsData = JSON.parse(
      JSON.stringify(inspectionForms[event.target.value].sections)
    );
    setSectionData(null);
    setSections(formSectionsData);
  };

  const handleChangeForm = (section, questionId, key, value) => {
    const sectionsClone = JSON.parse(JSON.stringify(sections));
    if (!sectionsClone[section]) return;
    sectionsClone[section].questions[questionId].questionData[key] = value;
    setSections(sectionsClone);
    setSectionData(sectionsClone);
  };

  const handleChangeFacility = (event) => {
    setFacility(facilitiesData[event.target.value].facilityName);
  };

  const handleChangeCompany = (event) => {
    setCompany(event.target.value);
  };

  const handleChangeAudit = (event) => {
    setAudit(event.target.value);
  };

  const handleChangeReviewLevel = (event) => {
    setReviewLevel(event.target.value);
  };

  const [openForm, setOpenForm] = React.useState(false);

  const handleClickOpenForm = () => {
    setOpenForm(true);
  };

  const handleClickEditForm = () => {
    setSelectedRowFormData();
    setFormEditing(true);
    setOpenForm(true);
  };

  const setSelectedRowFormData = () => {
    const rowData = inspections[selectionModel[0] - 1];
    setReviewType(rowData.reviewType);
    setFormType(
      inspectionForms.map((f) => f.formName).indexOf(rowData.formType)
    );
    setFacility(rowData.facility);
    setCompany(rowData.company);
    setAudit(rowData.auditName);
    setReviewLevel(rowData.reviewLevel);
    setStartDate(rowData.startDate);
    setEndDate(rowData.endDate);
    setAuditTeam(rowData.auditTeam);
    setAuditor(rowData.auditor);
    setAuditCompany(rowData.auditCompany);
    setScore(rowData.score);
    setResult(rowData.result);
    setAuditId(rowData.audit_id);
    setNotificationDays(rowData.notificationDays);
    setCoverageDays(rowData.coverageDays);
    setSections(rowData.sections);
    setSectionData(rowData.sections);
    setFdaApproved(rowData.fdaApproved);
    setFdaCertified(rowData.fdaCertified);
  };

  const handleClickViewForm = () => {
    setSelectedRowFormData();
    setViewOnly(true);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setReviewType("");
    setFormType(-1);
    setFacility("");
    setCompany("");
    setAudit("");
    setReviewLevel(0);
    setStartDate(0);
    setEndDate(0);
    setAuditTeam("");
    setAuditor("");
    setAuditCompany("");
    setScore("");
    setResult("");
    setNotificationDays("");
    setCoverageDays("");
    setSectionData(null);
    setFdaApproved(false);
    setFdaCertified(false);
    setOpenForm(false);
    setViewOnly(false);
    setFormEditing(false);
  };

  useEffect(() => {
    getInspectionForms();
  }, []);

  React.useEffect(() => {
    setShowProgress(true);
    getInspections()
      .then((data) => {
        setInspections(data);
        setShowProgress(false);
      })
      .catch((err) => {
        alert("Failed to load Inspections.");
        setShowProgress(false);
      });
  }, [tableRefreshDate]);

  return (
    <MenuComponent>
      <Grid container>
        <Dialog
          open={openForm}
          onClose={handleCloseForm}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Inspection form</DialogTitle>
          <DialogContent>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4} md={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Review type
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={reviewType}
                    disabled={viewOnly}
                    label="Review Type"
                    onChange={handleChangeReviewType}
                  >
                    <MenuItem value="inspection">Inspection</MenuItem>
                    <MenuItem value="audit">Audit</MenuItem>
                  </SelectInput>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Form type
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Form Type"
                    disabled={viewOnly}
                    value={formType}
                    onChange={handleChangeFormType}
                  >
                    {inspectionForms.map((form, index) => (
                      <MenuItem value={index}>{form.formName}</MenuItem>
                    ))}
                  </SelectInput>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Facility
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disabled={viewOnly}
                    label="Facility"
                    value={facilitiesData
                      .map((f) => f.facilityName)
                      .indexOf(facility)}
                    onChange={handleChangeFacility}
                  >
                    {facilitiesData.map((facility, index) => (
                      <MenuItem value={index}>{facility.facilityName}</MenuItem>
                    ))}
                  </SelectInput>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TextInput
                  required
                  variant="outlined"
                  value={auditTeam}
                  className={classes.formControlInput}
                  id="audit-name"
                  disabled={viewOnly}
                  name="audit-name"
                  label="Point of Contact"
                  onChange={handleChangeAuditTeam}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4}>
                <TextInput
                  required
                  variant="outlined"
                  value={company}
                  className={classes.formControl}
                  label="Company Name"
                  onChange={handleChangeCompany}
                />
              </Grid> */}
              <Grid item xs={12} sm={4} md={3}>
                <TextInput
                  required
                  value={audit}
                  variant="outlined"
                  className={classes.formControlInput}
                  id="audit-name"
                  disabled={viewOnly}
                  name="audit-name"
                  label="Inspection/Audit name"
                  autoComplete="audit-name"
                  onChange={handleChangeAudit}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TextInput
                  required
                  variant="outlined"
                  disabled={viewOnly}
                  value={auditor}
                  className={classes.formControlInput}
                  label="Lead Auditor Name"
                  onChange={handleChangeAuditor}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TextInput
                  required
                  variant="outlined"
                  disabled={viewOnly}
                  value={auditCompany}
                  className={classes.formControlInput}
                  label="Lead Auditor Company"
                  onChange={handleChangeAuditCompany}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Review Level
                  </InputLabel>
                  <SelectInput
                    value={reviewLevel}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disabled={viewOnly}
                    label="Review Level"
                    onChange={handleChangeReviewLevel}
                  >
                    <MenuItem value={3}>Internal</MenuItem>
                    <MenuItem value={4}>External</MenuItem>
                    <MenuItem value={5}>Approved 3rd Party</MenuItem>
                  </SelectInput>
                </FormControl>
              </Grid>

              <br />
              <br />
              <Grid container>
                <Grid item xs={12} sm={3} className="date-input-margin">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FormControlLabel
                    control={
                      <Input
                        disabled={viewOnly}
                        type="date"
                        value={startDate}
                        onChange={(event) => handleStartDateChange(event)}
                      />
                    }
                    label="Start Date"
                  />
                </Grid>
                {formType !== -1 && (
                  <FormComponent
                    form={{ ...inspectionForms[formType], sections }}
                    sectionData={viewOnly || formEditing ? sectionData : null}
                    disabled={viewOnly}
                    handleChangeForm={handleChangeForm}
                  />
                )}
                <Grid item xs={12} sm={4} className="date-input-margin">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FormControlLabel
                    control={
                      <Input
                        value={endDate}
                        disabled={viewOnly}
                        type="date"
                        onChange={(event) => handleEndDateChange(event)}
                      />
                    }
                    label="Completion Date"
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid item xs={12} sm={4} md={3}>
                <TextInput
                  required
                  variant="outlined"
                  placeholder=""
                  disabled={viewOnly}
                  value={score}
                  className={classes.formControlInput}
                  id="audit-name"
                  name="audit-name"
                  label="Score"
                  autoComplete="audit-name"
                  onChange={handleScoreChange}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Result</InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-label"
                    value={result}
                    disabled={viewOnly}
                    label="Result"
                    id="demo-simple-select"
                    onChange={handleResultChange}
                  >
                    <MenuItem value="pass">Pass</MenuItem>
                    <MenuItem value="fail">Fail</MenuItem>
                  </SelectInput>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <TextInput
                  variant="outlined"
                  value={coverageDays}
                  disabled={viewOnly}
                  placeholder=""
                  className={classes.formControlInput}
                  label="Coverage Days"
                  onChange={handleChangeCoverageDays}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TextInput
                  variant="outlined"
                  value={notificationDays}
                  disabled={viewOnly}
                  placeholder=""
                  className={classes.formControlInput}
                  label="Expiration Notification Days"
                  onChange={handleNotificationDaysChange}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={fdaApproved}
                      name="checkedC"
                      onChange={(event) => {
                        handleFDAChange("Approved", event.target.checked);
                      }}
                    />
                  }
                  label="FDA/FSIS Approved"
                /> */}
                <Box ml={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fdaCertified}
                        disabled={viewOnly}
                        name="checkedC"
                        onChange={(event) => {
                          handleFDAChange("Certified", event.target.checked);
                        }}
                      />
                    }
                    label="Certified"
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleCloseForm} color="primary">
              Cancel
            </Button> */}
            <Button
              onClick={() => {
                if (viewOnly) {
                  handleCloseForm();
                  return;
                }
                if (
                  reviewType === "" ||
                  facility === "" ||
                  // company === "" ||
                  audit === "" ||
                  formType === -1 ||
                  reviewLevel === 0 ||
                  startDate === 0 ||
                  endDate === 0 ||
                  auditTeam === "" ||
                  auditCompany === "" ||
                  auditor === "" ||
                  score === "" ||
                  result === ""
                ) {
                  alert("Please fill all required fields");
                  return;
                }
                const formData = {};
                formData["reviewType"] = reviewType;
                formData["facility"] =
                  facilitiesData[
                    facilitiesData.map((f) => f.facilityName).indexOf(facility)
                  ].facilityName;
                formData["company"] = formData["facility"]; //company;
                formData["entity_name"] = localStorage.getItem("group");
                formData["audit_id"] = formEditing
                  ? auditId
                  : Number(new Date()).toString();
                formData["auditName"] = audit;
                formData["formType"] = inspectionForms[formType].formName;
                formData["reviewLevel"] = reviewLevel;
                formData["startDate"] = startDate;
                formData["endDate"] = endDate;
                formData["auditTeam"] = auditTeam;
                formData["auditCompany"] = auditCompany;
                formData["auditor"] = auditor;
                formData["sections"] = sections;
                formData["score"] = score;
                formData["result"] = result;
                formData["coverageDays"] = coverageDays;
                formData["notificationDays"] = notificationDays;
                formData["fdaApproved"] = fdaApproved;
                formData["fdaCertified"] = fdaCertified;

                saveInspection(formData).then((data) => {
                  setTableRefreshDate(new Date());
                });
                handleCloseForm();
              }}
              className="full-color-button"
            >
              {viewOnly ? "Close" : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item md={12} className="flex-right-justified">
          {viewRowEnabled && (
            <Box p={2}>
              <Button
                variant="contained"
                className="full-color-button"
                onClick={handleClickEditForm}
              >
                Edit Inspection
              </Button>
            </Box>
          )}
          {viewRowEnabled && (
            <Box p={2}>
              <Button
                variant="contained"
                className="full-color-button"
                onClick={handleClickViewForm}
              >
                View Inspection
              </Button>
            </Box>
          )}
          <Box p={2}>
            <Button
              variant="contained"
              className="full-color-button"
              onClick={handleClickOpenForm}
            >
              New Inspection
            </Button>
          </Box>
        </Grid>
        <Grid item md={12} className="table-container-height">
          <div className="ag-theme-alpine grid-main">
            <Table
              columns={TABLE_HEADERS_INSPECTION}
              rows={inspections}
              handleRowClick={({ selectionModel }) => {
                setSelectionModel(selectionModel);
              }}
            />
            {/* <AgGridReact
              rowData={inspections}
              rowSelection="single"
              onRowClicked={(event) => {
                const rowData = event.api.getSelectedRows()[0];

                // formData["reviewType"] = reviewType;
                // formData["facility"] = facilitiesData[facility].facilityName;
                // formData["company"] = company;
                // formData["entity_name"] = user;
                // formData["audit_id"] = Number.parseInt(
                //   Math.random() * 100000
                // ).toString();
                // formData["auditName"] = audit;
                // formData["formType"] = inspectionForms[formType].formName;
                // formData["reviewLevel"] = reviewLevel;
                // formData["startDate"] = startDate;
                // formData["endDate"] = endDate;
                // formData["auditTeam"] = auditTeam;
                // formData["auditCompany"] = auditCompany;
                // formData["auditor"] = auditor;
                // formData["sections"] = sections;
                // formData["score"] = score;
                // formData["result"] = result;
                // formData["coverageDays"] = coverageDays;
                // formData["notificationDays"] = notificationDays;

                setReviewType(rowData.reviewType);
                setFormType(
                  inspectionForms.map((f) => f.formName).indexOf(rowData.formType)
                );

                setFacility(rowData.facility);
                setCompany(rowData.company);
                setAudit(rowData.audit);
                setReviewLevel(rowData.reviewLevel);
                setStartDate(rowData.startDate);
                setEndDate(rowData.endDate);
                setAuditTeam(rowData.auditTeam);
                setAuditor(rowData.auditor);
                setAuditCompany(rowData.auditCompany);
                setScore(rowData.score);
                setResult(rowData.result);
                setNotificationDays(rowData.notificationDays);
                setCoverageDays(rowData.coverageDays);
                setSectionData(rowData.sections);
                setFdaApproved(rowData.fdaApproved);
                setFdaCertified(rowData.fdaCertified);

                setOpenForm(true);
              }}
            >
              <AgGridColumn
                field="facility"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="company"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="auditName"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="formType"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="reviewLevel"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="startDate"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="endDate"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="auditTeam"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="auditCompany"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="auditor"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="sections"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="score"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="result"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="fdaApproved"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="fdaCertified"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="coverageDays"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="notificationDays"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
            </AgGridReact> */}
          </div>
        </Grid>
      </Grid>
      <Progress show={showProgress} />
    </MenuComponent>
  );
};
export default InspectionComponent;
