import React from "react";
import GridCellExpand from "./GridCellExpand";

function renderBoolenCellExpand(params) {
  return (
    <GridCellExpand
      value={
        params.value ? "Yes" : "No"
      }
      width={params.colDef.width}
    />
  );
}

export default renderBoolenCellExpand;
