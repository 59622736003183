import React from "react";
import { Button, Box } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import ROUTES from "common/Routes";

const NavBarButtons = ({ title = "", onActionButtonClick }) => {
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname.toString();
  const isActive = (menuItem) => {
    return pathname === menuItem;
  };

  return (
    <Box className="topmenu-buttons-container">
      <Button
        disableElevation
        onClick={() => history.push(ROUTES.LANDING.path)}
        className={isActive(ROUTES.LANDING.path) ? "primary-menu-button" : ""}
      >
        Facilities
      </Button>
      <Button
        disableElevation
        onClick={() => history.push(ROUTES.INSPECTION_FORM.path)}
        className={isActive(ROUTES.INSPECTION_FORM.path) ? "primary-menu-button" : ""}
      >
        Inspection Form
      </Button>
      <Button
        disableElevation
        onClick={() => history.push(ROUTES.INSPECTION.path)}
        className={isActive(ROUTES.INSPECTION.path) ? "primary-menu-button" : ""}
      >
        Inspection
      </Button>
    </Box>
  );
};

export default NavBarButtons;
