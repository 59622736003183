import React from "react";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  MenuItem,
  Menu,
} from "@material-ui/core";
import {
  AccountCircle,
} from "@material-ui/icons";
import logo from "assets/images/logo.png";
import NavBarButtons from "./navbuttons";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  grow: {
    flexGrow: 1,
  },
  navBar: {
    backgroundColor: "#FFFFFF",
    paddingRight: "5%",
  },
  title: {
    flexGrow: 1,
  },
}));


const NavBar = ({ showTopNavButtons = true }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const history = useHistory();
  
  const logoutHandler = () => {
    localStorage.clear();
    sessionStorage.clear();
    history.push("/login");
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={logoutHandler}>Logout</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.navBar}>
        <Toolbar>
          <img src={logo} alt="pcn-logo" className="pcn-menu-logo" />
          {showTopNavButtons && <NavBarButtons />}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
};

export default NavBar;
