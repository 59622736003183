import renderCellExpand from "../components/UI/Table/ExpandCellRenderer";
import renderBoolenCellExpand from "../components/UI/Table/BooleanRenderer";
import { US_STATES } from "./USStateList";

export const facilityTypes = [
  { _id: "605b45aa8c30678045194c56", label: "Farm", value: "FARM", __v: 0 },
  {
    _id: "605b45c08c30678045194c57",
    label: "Administrative/Office",
    value: "A/O",
    __v: 0,
  },
  {
    _id: "605b45d88c30678045194c58",
    label: "Hospital",
    value: "HOSPITAL",
    __v: 0,
  },
  {
    _id: "605b45e68c30678045194c59",
    label: "Residence",
    value: "RESIDENCE",
    __v: 0,
  },
  { _id: "605b45f88c30678045194c5a", label: "Hotel", value: "HOTEL", __v: 0 },
  { _id: "605b46038c30678045194c5b", label: "Field", value: "FIELD", __v: 0 },
  {
    _id: "605b461a8c30678045194c5c",
    label: "Distribution Center",
    value: "DISTRIBUTION_CENTER",
    __v: 0,
  },
  {
    _id: "605b463b8c30678045194c5d",
    label: "Manufacturing/Production Plant",
    value: "MANF/PROD_PLANT",
    __v: 0,
  },
  {
    _id: "605b46548c30678045194c5e",
    label: "Restaurant",
    value: "RESTAURANT",
    __v: 0,
  },
  {
    _id: "605b46618c30678045194c5f",
    label: "Retail Store",
    value: "RETAIL_STORE",
    __v: 0,
  },
  {
    label: "Vineyard",
    value: "VINEYARD",
  },
];

export const TABLE_HEADERS_MY_FACILITIES = [
  // {
  //   headerName: "PCN Id",
  //   field: "id",
  //   width: 275,
  //   renderCell: renderCellExpand,
  // },
  {
    headerName: "Facility Name",
    field: "facilityName",
    width: 275,
    renderCell: renderCellExpand,
  },
  // {
  //   headerName: "Facility GLN",
  //   field: "gln",
  //   width: 275,
  //   renderCell: renderCellExpand,
  // },
  // {
  //   headerName: "Type of Facility",
  //   field: "facilityType",
  //   width: 275,
  //   valueFormatter: (params) => {
  //     return facilityTypes?.find((el) => el.value === params.value)?.label;
  //   },
  // },
  // {
  //   headerName: "Description",
  //   field: "description",
  //   width: 275,
  //   renderCell: renderCellExpand,
  // },
  {
    headerName: "Facility Address",
    field: "facilityAddress",
    width: 475,
    renderCell: renderCellExpand,
  },
  // {
  //   headerName: "City",
  //   field: "city",
  //   width: 275,
  //   renderCell: renderCellExpand,
  // },
  // {
  //   headerName: "State",
  //   field: "state",
  //   width: 275,
  //   valueFormatter: (params) => {
  //     return US_STATES?.find((el) => el.value === params.value)?.label;
  //   },
  // },
  // {
  //   headerName: "Postal Code",
  //   field: "zipCode",
  //   width: 275,
  //   renderCell: renderCellExpand,
  // },
  // {
  //   headerName: "Latitude",
  //   field: "latitude",
  //   width: 275,
  //   renderCell: renderCellExpand,
  // },
  // {
  //   headerName: "Longitude",
  //   field: "longitude",
  //   width: 275,
  //   renderCell: renderCellExpand,
  // },
  // {
  //   headerName: "Phone Number",
  //   field: "phoneNumber",
  //   width: 275,
  //   renderCell: renderCellExpand,
  //   valueGetter: (params) => {
  //     return params?.row?.phoneNumber?.number
  //       ? `${params?.row?.phoneNumber?.code}${params?.row?.phoneNumber?.number}`
  //       : "";
  //   },
  // },
  // {
  //   headerName: "Email Address",
  //   field: "email",
  //   width: 275,
  //   renderCell: renderCellExpand,
  // },
  {
    headerName: "Country",
    field: "country",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Point of Contact",
    field: "pointOfContact",
    width: 275,
    renderCell: renderCellExpand,
  }
  
];

export const TABLE_HEADERS_INSPECTION_FORM = [
  {
    headerName: "Form Name",
    field: "formName",
    width: 400,
    renderCell: renderCellExpand,
  },  
  {
    headerName: "Form Type",
    field: "formType",
    width: 275,
    renderCell: renderCellExpand,
  },  
]

export const TABLE_HEADERS_INSPECTION = [
  // {
  //   headerName: "Id",
  //   field: "id",
  //   width: 275,
  //   renderCell: renderCellExpand,
  // },
  {
    headerName: "Review Type",
    field: "reviewType",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Form Type",
    field: "formType",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Facility",
    field: "facility",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Point of Contact",
    field: "auditTeam",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Audit Name",
    field: "auditName",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Lead Auditor Name",
    field: "auditor",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Lead Auditor Company",
    field: "auditCompany",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Review Level",
    field: "reviewLevel",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Start Date",
    field: "startDate",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Completion Date",
    field: "endDate",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Score",
    field: "score",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Result",
    field: "result",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Coverage Days",
    field: "coverageDays",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Expiration Notification Days",
    field: "notificationDays",
    width: 275,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Certified",
    field: "fdaCertified",
    width: 275,
    renderCell: renderBoolenCellExpand,
  },
];
