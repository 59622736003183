import React from "react";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  progressDialog: {
    background: "transparent",
    margin: 0,
    padding: "10px",
    boxShadow: "none",
  },
}));

const Progress = (props) => {
  const classes = useStyles();
  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      classes={{
        paper: classes.progressDialog,
      }}
      maxWidth="lg"
      open={props.show}
    >
      <CircularProgress style={{ color: "#0f0" }} disableShrink />
    </Dialog>
  );
};

export default Progress;
