import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Divider,
  Paper,
} from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getInspectionForms } from "../../common/dataGetters";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SectionComponent from "./section";
import MenuComponent from "../MenuComponent";
import Table from "../UI/Table";
import { TABLE_HEADERS_INSPECTION_FORM } from "../../common/constants";
import SelectInput from "../UI/SelectInput";
import TextInput from "../UI/TextInput";
import { useEffect } from "react";
import { saveInspectionForm } from "../../common/dataUpdators";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  sectionContainer: {
    border: "1px solid #e4e0e0",
    margin: "5px 0",
  },
}));

const InspectionFormComponent = (props) => {
  const [inspectionData, setInspectionData] = useState(
    JSON.parse(localStorage.getItem("inspectionForms"))
  );
  const user = localStorage.getItem("user");
  const classes = useStyles();
  const [reviewType, setReviewType] = React.useState("");
  const [formType, setFormType] = React.useState("");
  const [sectionValue, setSectionValue] = React.useState([]);
  const [facility, setFacility] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [formName, setformName] = React.useState("");
  const [sections, setSections] = React.useState([]);
  const [sectionData, setSectionData] = React.useState({});
  const [lastTableRefresh, setLastTableRefresh] = React.useState(new Date());
  const [selectionModel, setSelectionModel] = useState([]);
  const [viewRowEnabled, setViewRowEnabled] = useState([]);
  const token = localStorage.getItem("token");
  const history = useHistory();
  if (!token) {
    history.push("/login");
  }
  useEffect(() => {
    setViewRowEnabled(selectionModel.length === 1);
  }, [selectionModel]);

  const handleChangeReviewType = (event) => {
    setReviewType(event.target.value);
  };

  const handleChangeFormType = (event) => {
    setFormType(event.target.value);
  };

  const handleChangeFacility = (event) => {
    setFacility(event.target.value);
  };

  const handleChangeCompany = (event) => {
    setCompany(event.target.value);
  };

  const handleChangeformName = (event) => {
    setformName(event.target.value);
  };

  const [openForm, setOpenForm] = React.useState(false);
  const [openViewForm, setOpenViewForm] = React.useState(false);
  const [formEditing, setFormEditing] = React.useState(false);

  const handleClickOpenForm = () => {
    setOpenForm(true);
  };

  const handleClickViewForm = () => {
    const data = inspectionData[selectionModel[0] - 1];
    setformName(data.formName);
    setFormType(data.formType);
    setSectionValue(data.sections || []);
    setOpenViewForm(true);
  };

  const handleClickEditForm = () => {
    const data = inspectionData[selectionModel[0] - 1];
    setformName(data.formName);
    setFormType(data.formType);
    setSectionValue(data.sections || []);
    prepareSection(data.sections);
    setFormEditing(true);
    setOpenForm(true);
  };

  const prepareSection = (sectionData = []) => {
    const allSections = sectionData.map((data, index) => {
      const key = Date.now() + index;
      getSectionData(key, data.sectionName, data.questions);
      return (
        <SectionComponent
          uniqueKey={key}
          data={data}
          getSectionData={getSectionData}
        />
      );
    });

    setSections(allSections);
  };

  const handleCloseForm = () => {
    setSectionData({});
    setSections([]);
    setFormType("");
    setformName("");
    setOpenViewForm(false);
    setOpenForm(false);
    setFormEditing(false);
  };

  const getSectionData = (key, sectionName, questions) => {
    sectionData[key] = {
      sectionName,
      questions,
    };
    setSectionData(sectionData);
  };

  React.useEffect(() => {
    getInspectionForms().then((data) => {
      setInspectionData(data);
    });
  }, [lastTableRefresh]);

  const hasValue = (data) => {
    return Boolean(data) && Boolean(String(data).trim());
  };

  const isFormNameUnique = (name) => {
    if (formEditing) return true;
    const foundForm = (inspectionData || []).find(
      (item) => item.form_name === name
    );
    return !Boolean(foundForm);
  };

  const isFormValid = () => {
    return hasValue(formName) && hasValue(formType);
  };

  const submitHandler = () => {
    if (!isFormNameUnique(formName)) {
      alert("Form with same name already exists");
      return;
    }
    if (!isFormValid()) {
      alert("Please fill all required fields");
      return;
    }
    const formData = {};
    formData["formName"] = formName;
    formData["form_name"] = formName;
    formData["form_creater"] = localStorage.getItem("group");
    formData["formType"] = formType;
    formData["sections"] = Object.values(sectionData);
    formData["id"] = Number(new Date()).toString();
    saveInspectionForm(formData)
      .then((data) => {
        setLastTableRefresh(new Date());
      })
      .catch((err) => {
        alert("Failed to save data.");
      });
    setSectionData({});
    handleCloseForm();
  };

  return (
    <MenuComponent>
      <Grid container>
        <Dialog
          open={openViewForm}
          onClose={handleCloseForm}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Inspection form</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={2}>
                Form Type:
              </Grid>
              <Grid item md={10}>
                {formType}
              </Grid>
              <Grid item md={2}>
                Form Name:
              </Grid>
              <Grid item md={10}>
                {formName}
              </Grid>
              {sectionValue.map((sec, index) => {
                return (
                  <Grid
                    container
                    item
                    md={12}
                    className={classes.sectionContainer}
                  >
                    <Grid item md={2}>
                      <b>Section:</b>
                    </Grid>
                    <Grid item md={10}>
                      <b>{sec.sectionName}</b>
                    </Grid>
                    {Object.keys(sec.questions)
                      .sort((a, b) => Number(a) - Number(b))
                      .map((id, ind) => {
                        return (
                          <>
                            <Grid item md={2}>
                              Question {ind + 1}:
                            </Grid>
                            <Grid item md={10}>
                              {sec.questions[id].questionData.question}
                            </Grid>
                          </>
                        );
                      })}
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseForm} color="primary">
              Cancel
            </Button>{" "}
          </DialogActions>
        </Dialog>
        <Dialog
          open={openForm}
          onClose={handleCloseForm}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Inspection form</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              {/* <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Review type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={reviewType}
                    onChange={handleChangeReviewType}
                  >
                    <MenuItem value={10}>Inspection</MenuItem>
                    <MenuItem value={20}>formName</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  {/* <InputLabel id="demo-simple-select-label">Form type</InputLabel> */}
                  <SelectInput
                    labelId="select-label-form-type"
                    label="Form Type"
                    required
                    id="simple-select-form-type"
                    value={formType}
                    onChange={handleChangeFormType}
                  >
                    <MenuItem value="inspection">Inspection</MenuItem>
                    <MenuItem value="audit">Audit</MenuItem>
                  </SelectInput>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Facility</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={facility}
                    onChange={handleChangeFacility}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Company</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={company}
                    onChange={handleChangeCompany}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={6}>
                <TextInput
                  required
                  id="formName-name"
                  name="formName-name"
                  label="Form Name"
                  variant="outlined"
                  value={formName}
                  disabled={formEditing}
                  autoComplete="formName-name"
                  onChange={handleChangeformName}
                />
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <Button
                  size="small"
                  variant="contained"
                  className="full-color-button"
                  onClick={() => {
                    setSections([
                      ...sections,
                      <SectionComponent
                        uniqueKey={Date.now()}
                        getSectionData={getSectionData}
                      />,
                    ]);
                  }}
                >
                  Add Section
                </Button>
                &nbsp;
                <Button
                  size="small"
                  variant="contained"
                  className="full-color-button"
                  onClick={() => {
                    const section = sections.pop();
                    if (!section) return;
                    delete sectionData[section.props.uniqueKey];
                    setSectionData(sectionData);
                    setSections([...sections]);
                  }}
                >
                  Remove Section
                </Button>
              </Grid>
              <Grid item xs={12} sm={10} md={8}>
                {sections.map((section) => {
                  return section;
                })}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleCloseForm} color="primary">
              Cancel
            </Button> */}
            <Button onClick={submitHandler} className="full-color-button">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item md={12} className="flex-right-justified">
          <Box p={2}>
            {viewRowEnabled && (
              <Button
                variant="contained"
                className="full-color-button"
                onClick={handleClickEditForm}
              >
                Edit Form
              </Button>
            )}
          </Box>
          <Box p={2}>
            {viewRowEnabled && (
              <Button
                variant="contained"
                className="full-color-button"
                onClick={handleClickViewForm}
              >
                View Form
              </Button>
            )}
          </Box>
          <Box p={2}>
            <Button
              variant="contained"
              className="full-color-button"
              onClick={handleClickOpenForm}
            >
              Add Form
            </Button>
          </Box>
        </Grid>
        <Grid item md={12} className="table-container-height">
          <div className="ag-theme-alpine grid-main">
            <Table
              columns={TABLE_HEADERS_INSPECTION_FORM}
              rows={inspectionData}
              handleRowClick={({ selectionModel }) => {
                setSelectionModel(selectionModel);
              }}
            />
            {/* <AgGridReact
              rowData={inspectionData}
              rowSelection="single"
              onRowClicked={(event) => {
                setformName(event.api.getSelectedRows()[0].formName);
                setFormType(event.api.getSelectedRows()[0].formType);
                setSectionValue(event.api.getSelectedRows()[0].sections || []);
                setOpenViewForm(true);
              }}
            >
              <AgGridColumn
                field="reviewType"
                sortable={true}
                filter={true}
              ></AgGridColumn> 
              <AgGridColumn
                field="formType"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              <AgGridColumn
                field="formName"
                sortable={true}
                filter={true}
                resizable
              ></AgGridColumn>
              {/* <AgGridColumn
                field="formName"
                sortable={true}
                filter={true}
              ></AgGridColumn>
              <AgGridColumn
                field="facility"
                sortable={true}
                filter={true}
              ></AgGridColumn>
              <AgGridColumn
                field="company"
                sortable={true}
                filter={true}
              ></AgGridColumn> 
            </AgGridReact> */}
          </div>
        </Grid>
      </Grid>
    </MenuComponent>
  );
};
export default InspectionFormComponent;
