import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import usersData from "data/usersData.json";
import logo from "assets/images/logo.png";
import "react-datepicker/dist/react-datepicker.css";
import { Alert } from "@material-ui/lab";
import TextInput from "../UI/TextInput";
import clsx from "clsx";
import { getToken } from "../../auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tncContainer: {
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    background: theme.palette.common.white,
    padding: "3rem",
    borderRadius: "0.3rem",
    maxWidth: "25rem",
    width: "60%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  tcnBtn: {
    borderRadius: "5rem",
    minWidth: "15rem",
    fontWeight: "600",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginComponent = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showProgress, setShowProgress] = React.useState(false);
  const [dataFetched, setDataFetched] = React.useState(0);
  const [error, setError] = React.useState("");

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  localStorage.setItem("referenceDate", selectedDate);

  const signIn = () => {
    setShowProgress(true);
    getToken(email, password)
      .then((resp) => resp.json())
      .then((token) => {
        localStorage.setItem("token", token);
        const user = usersData.users.filter((user) => user.email === email)[0];
        if (user) {
          localStorage.setItem("facilitiesData", JSON.stringify([]));
          localStorage.setItem("inspectionForms", JSON.stringify([]));
          localStorage.setItem("group", user.group);
          localStorage.setItem("user", user.brand);
          setShowProgress(false);
          history.push("/");
        }
      })
      .catch((err) => {
        setError("Invalid Email or Password");
        setShowProgress(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box className={classes.tncContainer} boxShadow={5}>
        <div className={classes.paper}>
          <img src={logo} alt="Logo" className="logo" />
          <br />
          <br />
          <TextInput
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextInput
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event) => setPassword(event.target.value)}
          />
          <br />
          {error && <Alert severity="error">{error}</Alert>}
          <br />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={clsx(classes.tcnBtn, "full-color-button")}
            onClick={() => signIn()}
          >
            Sign In
          </Button>
          <br />
          {showProgress && <CircularProgress />}
        </div>
      </Box>
    </Container>
  );
};

export default LoginComponent;
