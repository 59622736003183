import React from "react";
import GridCellExpand from "./GridCellExpand";

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={
        params.value
          ? Array.isArray(params.value)
            ? params.value.join(", ")
            : params.value.toString()
          : ""
      }
      width={params.colDef.width}
    />
  );
}

export default renderCellExpand;
