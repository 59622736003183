import React from "react";
import { Box } from "@material-ui/core";
import NavBar from "../NavBar";

const PageLayout = ({
  children,
}) => {
  return (
   <Box height="100vh" display="flex" flexDirection="column">
      <NavBar
        showTopNavButtons
      />
      <Box flex={1} overflow="auto">
        {children}
      </Box>
    </Box>
  );
};
export default PageLayout;
