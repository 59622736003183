import { post } from "./api";
import {
  FACILITY_URL,
  INSPECTIONFORM_URL,
  ISNPECTIONS_URL,
} from "./api/endpoints";
import { handleResponse } from "./dataGetters";

export const saveFacility = async (data) => {
  return post(FACILITY_URL, data)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const saveInspectionForm = async (data) => {
  return post(INSPECTIONFORM_URL, data)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const saveInspection = async (data) => {
  return post(ISNPECTIONS_URL, data)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};
