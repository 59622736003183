import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Col,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  TextField,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextInput from "../UI/TextInput";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordion: {
    border: "1px solid #d3d3d3",
    "& .MuiAccordionSummary-content": {
      justifyContent: "center",
    },
  },
}));

const FormComponent = (props) => {
  const { form, handleChangeForm, sectionData, disabled } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [scrollTarget, setScrollTarget] = React.useState(null);

  const [value, setValue] = React.useState("yes");

  React.useEffect(() => {
    setTimeout(() => {
      scrollTarget && scrollTarget.scrollIntoView();
    }, 10);
  }, [scrollTarget]);

  const handleChangeValue = (event) => {
    setValue(event.target.value);
  };

  const handleChange = (panel, evt) => (event, isExpanded) => {
    const target = event.target;
    setExpanded(isExpanded ? panel : false);
    setTimeout(() => {
      setScrollTarget(target);
    }, 50);
  };

  return (
    <div className={classes.root}>
      <Divider />
      {(sectionData || form.sections).map((section, index) => (
        <Accordion
          expanded={expanded === "panel" + index}
          onChange={handleChange("panel" + index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.accordion}
          >
            {/* <Typography className={classes.heading}>Section</Typography> */}
            <Typography variant="h6" className={classes.secondaryHeading}>
              {section.sectionName}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              style={{ pointerEvents: disabled ? "none" : "initial" }}
            >
              {Object.keys(section.questions)
                .sort((a, b) => Number(a) - Number(b))
                .map((questionNumber) => (
                  <Grid item md={12}>
                    {section.questions[questionNumber].questionData.question}
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend"></FormLabel>
                          <RadioGroup
                            value={
                              section.questions[questionNumber].questionData
                                .answer
                            }
                            onChange={(event) =>
                              handleChangeForm(
                                index,
                                questionNumber,
                                "answer",
                                event.target.value
                              )
                            }
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                            <FormControlLabel
                              value="na"
                              control={<Radio />}
                              label="NA"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checkedC"
                              checked={
                                section.questions[questionNumber].questionData
                                  .docReview || false
                              }
                              onChange={(event) =>
                                handleChangeForm(
                                  index,
                                  questionNumber,
                                  "docReview",
                                  event.target.checked
                                )
                              }
                            />
                          }
                          label="Document Review"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checkedC"
                              checked={
                                section.questions[questionNumber].questionData
                                  .interviews || false
                              }
                              onChange={(event) =>
                                handleChangeForm(
                                  index,
                                  questionNumber,
                                  "interviews",
                                  event.target.checked
                                )
                              }
                            />
                          }
                          label="Interviews"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FormControlLabel
                          control={
                            <TextInput type="file" onChange={(event) => {}} />
                          }
                          label=" Observations Confirming"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FormControlLabel
                          control={
                            <TextInput type="file" onChange={(event) => {}} />
                          }
                          label=" Observations Refuting"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FormControlLabel
                          control={
                            <TextInput type="file" onChange={(event) => {}} />
                          }
                          label="Minor Incidents"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FormControlLabel
                          control={
                            <TextInput type="file" onChange={(event) => {}} />
                          }
                          label="Major Incidents"
                        />
                      </Grid>

                      <Grid item md={6}>
                        <FormControl className={classes.formControl}>
                          {/* <InputLabel id="demo-simple-select-label">
                          COMMENTS
                        </InputLabel> */}
                          <TextInput
                            multiline
                            fullWidth
                            label="Comments"
                            variant="outlined"
                            value={
                              section.questions[questionNumber].questionData
                                .comments || ""
                            }
                            onChange={(event) =>
                              handleChangeForm(
                                index,
                                questionNumber,
                                "comments",
                                event.target.value
                              )
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl className={classes.formControl}>
                          {/* <InputLabel id="demo-simple-select-label">
                          Score
                        </InputLabel> */}
                          <TextInput
                            variant="outlined"
                            label="Score"
                            value={
                              section.questions[questionNumber].questionData
                                .score || ""
                            }
                            onChange={(event) =>
                              handleChangeForm(
                                index,
                                questionNumber,
                                "score",
                                event.target.value
                              )
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FormComponent;
