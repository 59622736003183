import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "#EEEEEE",
    },
    "& .MuiDataGrid-row.Mui-selected:hover": {
      backgroundColor: "#EEEEEE",
    },
    "& .MuiCheckbox-colorPrimary.Mui-checked": {
      color: "#06A5D1",
    },
  },
}));

function Table({ columns, rows, handleRowClick, checkboxSelection = true }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <DataGrid
        checkboxSelection={checkboxSelection}
        columns={columns}
        rows={rows}
        headerHeight={40}
        onSelectionModelChange={(newSelection) => {
          handleRowClick && handleRowClick(newSelection);
        }}
      />
    </div>
  );
}

export default Table;
