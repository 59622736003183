import React, { useEffect, useState } from "react";
import { Grid, Button, Paper } from "@material-ui/core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import TextField from "@material-ui/core/TextField";
import QuestionComponent from "./question";
import TextInput from "../UI/TextInput";

const SectionComponent = (props) => {
  const [sectionName, setSectionName] = useState("");
  const [questions, setQuestions] = React.useState([]);
  const [questionData, setQuestionData] = React.useState({});

  useEffect(() => {
    if (props.data) {
      setSectionName(props.data.sectionName);
      setQuestionData(props.data.questions);
      prepareQuestions(props.data.questions);
    }
  }, [props.data]);

  const prepareQuestions = (questionsData = {}) => {
    const allQuestions = Object.keys(questionsData)
      .sort((a, b) => Number(a) - Number(b))
      .map((key) => {
        return (
          <QuestionComponent
            data={questionsData[key]}
            uniqueKey={key}
            getQuestionsData={getQuestionData}
          />
        );
      });
    setQuestions(allQuestions);
  };

  const handleChangeSectionName = (event) => {
    setSectionName(event.target.value);
    props.getSectionData(props.uniqueKey, event.target.value, questionData);
  };

  const getQuestionData = (key, data) => {
    questionData[key] = {
      questionData: data,
    };
    setQuestionData(questionData);
  };

  return (
    <div key={props.uniqueKey}>
      <Paper elevation={12} style={{ padding: 10 }} key={props.uniqueKey}>
        <Grid container item xs={12} md={12} spacing={1}>
          <Grid item xs={12} sm={4} md={4}>
            <TextInput
              required
              variant="outlined"
              id="section-name"
              name="section-name"
              label="Section name"
              value={sectionName}
              onChange={handleChangeSectionName}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={4} className="margin-auto">
            <Button
              size="small"
              variant="contained"
              fullWidth
              className="full-color-button w-100"
              onClick={() => {
                setQuestions([
                  ...questions,
                  <QuestionComponent
                    uniqueKey={Date.now()}
                    getQuestionsData={getQuestionData}
                  />,
                ]);
              }}
            >
              Add Question
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={4} className="margin-auto">
            <Button
              size="small"
              variant="contained"
              fullWidth
              className="full-color-button w-100"
              onClick={() => {
                const question = questions.pop();
                delete questionData[question.props.uniqueKey];
                setQuestionData(questionData);
                setQuestions([...questions]);
              }}
            >
              Remove Question
            </Button>
          </Grid>
          {/* <Grid item container xs={12} sm={6} md={8}>
            
          </Grid> */}

          {questions.map((section) => {
            return section;
          })}
        </Grid>
      </Paper>
      <br />
    </div>
  );
};

export default SectionComponent;
